<template>
  <div>
    <label class="debit-value-label" v-if="parseFloat(value.credit) === 0">
      -{{ value.debit }}
    </label>
    <label class="credit-value-label" v-else>
      {{ value.credit }}
    </label>
  </div>
</template>
<script>
  export default {
    props: {
      value: {
        type: Object,
        default: () => {},
      }
    }
  }
</script>

<style scoped>
  .credit-value-label {
    color: #27a91f;
    font-weight: bold;
  }
  .debit-value-label {
    color: #FF0101;
    font-family: sans-serif;
    font-weight: bold;
  }
</style>
